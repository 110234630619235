import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import querystring from "querystring";
import { decode } from "url-encode-decode";
import "./pagesCss/login.css";
import { TextField } from "@mui/material";

import { notify } from "../utils/notification";
import { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import officeBanaoLogo from "./officeBanoaLogo.png";
import canvaRoundLogo from "./canvaRoundLogo.png";
const App = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [canvaState, setCanvaState] = useState("");
  const [userID, setUserID] = useState("");
  const [isLoading, setLoading] = useState(false);

  const { search } = useLocation();

  useEffect(() => {
    if (search !== "") {
      setCanvaState(search.split("&")[4].split("=")[1]);
      setUserID(decode(search.split("&")[0].split("=")[1]));
    }
  }, [search]);

  async function loginUser(event) {
    event.preventDefault();
    console.log(userID);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}auth/v1/canva/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            password,
            userID,
          }),
        }
      );

      console.log(response);
      if (response.status !== 200) {
        setLoading(false);
        throw Error(
          JSON.stringify({
            code: response.status,
            message: response.statusText,
          })
        );
      }

      const data = await response.json();
      if (data.success) {
        // if (data.user) {
        notify("success", "Login Successfully");

        const params = querystring.stringify({
          success: true,
          state: canvaState,
        });
        setLoading(false);

        window.open(`https://www.canva.com/apps/configured?${params}`, "_self");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);

      notify("error", JSON.parse(error.message).message);
    }
  }

  const Loader = () => {
    return (
      <div className="ob-loader-container">
        <div className="ob-loader" />
      </div>
    );
  };
  return (
    <div className="bg-image">
      {isLoading && <Loader />}
      <Toaster />

      <div className="loginPage">
        <div className="canvaRoundLogo">
          <img src={canvaRoundLogo} height="70px" width="70px" alt="" />
        </div>
        <Card
          style={{
            minWidth: 350,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            padding: 40,
            alignItems: "center",
            margin: 15,
          }}
        >
          <img src={officeBanaoLogo} height="30px" alt="" />
          <Typography
            style={{ fontSize: 12, margin: 20 }}
            color="text.secondary"
          >
            Access using OfficeBanao Email and Password
          </Typography>
          <TextField
            style={{ width: 290, margin: 10 }}
            id="outlined-basic"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            style={{ width: 290, margin: 10 }}
            id="outlined-basic"
            label="Password"
            variant="outlined"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <CardActions>
            <Button
              style={{ width: 300, height: 40 }}
              size="small"
              variant="contained"
              onClick={(event) => {
                setLoading(true);
                loginUser(event);
              }}
            >
              SIGN IN
            </Button>
          </CardActions>
        </Card>
        <Typography
          style={{
            fontSize: 10,
            margin: 2,
            color: "white",
            fontFamily: "sans-serif",
          }}
        >
          © Office Banao. All Rights Reserved
        </Typography>
      </div>
    </div>
  );
};

export default App;

// Login with below url having user, state paramenters in URL query
// http://localhost:3000/?user=AT9JNMjd4Ao62aicZT2HYFgPUHP46ttrDtBffdYjDw0%3D&brand=AT9JNMjyKFP8Pyx97XGcYz5ZVm_rIBdcxXTW_mPn4yI%3D&extensions=CONTENT&time=1666175081&state=-b3GW1qUeaaMNndY1yK9e0uXFeWszcU6iRyOIsoXKvX-fZQPjICksO6lHh9lxl_0jd8KS0hGIlfaHEJRCJFxuvu0QUqrIgAxGa71FbT-VsnF9kEDFJ4xsp8aDhvd6C2uO6sML5SLzxa08PjnIsWrETyvKcEJ21uRis44c1IZeW_aTEY58N9Wsw3COxpTuw15JBKwwVwjgml6aVXKsi9VYhOiBt4&signatures=bda71b63c8435ac373f71751665a13feb44d7c45b6e47a88f3822e0edd9ef768

// https://silver-bavarois-1eda0d.netlify.app/?
// user=AT9JNMjd4Ao62aicZT2HYFgPUHP46ttrDtBffdYjDw0%3D&
// brand=AT9JNMjyKFP8Pyx97XGcYz5ZVm_rIBdcxXTW_mPn4yI%3D&
// extensions=CONTENT&
// time=1666161267&
// state=te-hIG7jpHWoX-LHbmCr3hZLwKS-3FRBLZJzUlKf5N19R9D3KXUi-nGsp5U_LAdFGkOnubMSaDD1j3QcC43GXm7-4X_2Mi3ReftpStSA7xJQIVWgTUruhTFXlzyj6cqQZ_8KuPjZ9flBM_6H0bonnoreFbsWMhXaBppIUuzGKZTKAr4EFglrdLk1CFw9bk5khIK31V2AyWwXdLdcT6aoPm1x3go&
// signatures=2aec359279291530354b45384f69485cdc697f41fa602e1e79cd407603b3fcdb
